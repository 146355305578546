/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Mornese from "@components/sharedComponents/customersLogos/logos/Mornese.svg"
import Recupera from "@components/sharedComponents/customersLogos/logos/RecuperaTwo.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import LosAndes from "@components/sharedComponents/customersLogos/logos/LosAndes.svg"
import Ucatel from "@components/sharedComponents/customersLogos/logos/Ucatel.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/frases-para-deudores.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/manejo-objeciones-cobranza.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/guion-cobranza-telefonica.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/tecnicas-cobranza-clientes-dificiles.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/fidelizar-clientes-banco.jpg"

// logos testimonios
import MorneseTes from "@components/sharedComponents/testimonies/images/logos/mornese.svg"
import RecuperaTes from "@components/sharedComponents/testimonies/images/logos/recupera.svg"
import IbrTes from "@components/sharedComponents/testimonies/images/logos/ibr.svg"
import gtAsesoresTes from "@components/sharedComponents/testimonies/images/logos/g&tAsesores.svg"

//foto testimonio desktop
import morneseDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/mornese.png"
import recuperaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/recupera.png"
import ibrDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ibr.png"
import gtAsesoresDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/g&tAsesores.png"

//foto testimonio desktop
import morneseMobile from "@components/sharedComponents/testimonies/images/fotosMobile/mornese.png"
import recuperaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/recupera.png"
import ibrMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ibr.png"
import gtAsesoresMobile from "@components/sharedComponents/testimonies/images/fotosMobile/g&tAsesores.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageCasosdeUso/pageCobros/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageCasosdeUso/pageCobros/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageCasosdeUso/pageCobros/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageCasosdeUso/pageCobros/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageCasosdeUso/pageCobros/images/accordionLeft/imageFive.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageCobros.jpg"

import { TitleUnderlineCobrosOne, TitleUnderlineCobrosTwo, TitleUnderlineCobrosThree } from "@components/pageCasosdeUso/components/intro/titleUnderlineCobros"
import imageStartCobros from "@components/pageCasosdeUso/components/intro/images/imageStartCobros.webp"

const titleStart = (
  <p>
    Optimiza tus cobros con IA y mejora la eficiencia en la <TitleUnderlineCobrosOne underline="recuperación" /> <TitleUnderlineCobrosTwo underline="de tus" /> <TitleUnderlineCobrosThree underline="carteras" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Aprovecha nuestra tecnología para optimizar la gestión de cobros mediante el lanzamiento de campañas telefónicas predictivas, automatización de procesos, análisis avanzado de datos y la integración con sistemas de gestión de deudas y análisis de riesgos.
        <br /> <br />
        Mejora tu eficiencia operativa, aumenta la recuperación de deudas, optimiza la experiencia del cliente y toma decisiones estratégicas de cobros basadas en datos.
      </p>
    ),
    image: imageStartCobros,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Mornese,
    Recupera,
    Conexo,
    Ibr,
    LosAndes,
    Ucatel
  ],
  customersTablet: {
    arrayOne: [Mornese, Recupera, Conexo, Ibr, LosAndes, Ucatel,]
  },
  articles: [
    {
      image: article1,
      title: "10 frases para deudores que aseguran la recuperación",
      textbody:
        "Conoce estas frases para deudores que aseguran la recuperación de deudas.",
      link: "https://beexcc.com/blog/frases-para-deudores/",
      card: true,
    },
    {
      image: article2,
      title: "10 consejos para un mejor manejo de objeciones en cobranza",
      textbody:
        "Conoce 10 consejos en el manejo de objeciones en cobranza y recupera las deudas de tu negocio sin perder clientes.",
      link: "https://beexcc.com/blog/manejo-objeciones-cobranza/",
      card: true,
    },
    {
      image: article3,
      title: "Guion de cobranza telefónica: ejemplos y pasos para elaborarlo con éxito",
      textbody:
        "¿Qué es y cómo hacer un guion de cobranza telefónica? Conoce 5 ejemplos y el paso a paso.",
      link: "https://beexcc.com/blog/guion-cobranza-telefonica/",
    },
    {
      image: article4,
      title: "10 técnicas de cobranza para clientes difíciles",
      textbody:
        "¿Quieres cobrar a tus clientes difíciles? Conoce 10 técnicas para lograrlo, que van desde la escucha activa hasta el uso la tecnología.",
      link: "https://beexcc.com/blog/tecnicas-cobranza-clientes-dificiles/",
    },
    {
      image: article5,
      title: "Cómo fidelizar clientes para un banco para volverlos embajadores",
      textbody:
        "Entérate en este artículo sobre cómo fidelizar clientes en un banco para que así puedas tener más usuarios que regresarán a tu negocio.",
      link: "https://beexcc.com/blog/fidelizar-clientes-banco/",
    },
  ],
  contacts: {
    title: "Asegura la recuperación de todas tus carteas de cobranza",
    textbody: "Cuéntanos todos los detalles de tus operaciones y te compartiremos feedback importante, el cuál te permitirá obtener una mejor performance en tus resultados.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: MorneseTes,
      title:
        "Beex nos permite barrer 3 veces más rápido y mejor nuestras bases de cobros",
      textbody:
        "Mornese, holding de capitales argentinos con presencia en LATAM y EU, usa Beex en la nube para su operación de más de 400 teleoperadores.",
      name: "Noelia Molina",
      post: "Gerente de Planeamiento y Estrategias",
      link: "/casos-de-exito/mornese/",
      imgDesktop: morneseDesktop,
      imgMobile: morneseMobile,
      metrics: [
        {
          metric: "+47%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+35%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de sus bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: RecuperaTes,
      title:
        "Beex y su marcación predictiva ha logrado aumentar en +28% nuestra contactabilidad",
      textbody:
        "Grupo Recupera, experto en cobros para sector financiero, comercial, retail, servicios y más, ha mejorado sus índices de cobros con marcaciones inteligentes e IVR.",
      name: "Marco Salinas",
      post: "Gerente Comercial",
      link: "/casos-de-exito/grupo-recupera/",
      imgDesktop: recuperaDesktop,
      imgMobile: recuperaMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#FFB800",
        },
        {
          metric: "+28%",
          descripcion: "aumentó su contactabilidad",
          color: "#5831D8",
        },
        {
          metric: "90%",
          descripcion: "logró de índice de adherencia",
          color: "#00CA56",
        },
      ],
    },
    {
      image: gtAsesoresTes,
      title: "Con las marcaciones telefónicas y procesos de IVR de Beex, hemos mejorado nuestra tasa de recupero",
      textbody:
        "GyT Asesores, empresa especializada en cobranza temprana, ha mejorado sus principales métricas de contacto y recupero con la suite de Beex Cloud, incluido CRM para cobros.",
      name: "Luis Gutiérrez Trujillo",
      post: "Gerente de Operaciones & BI",
      link: "/casos-de-exito/gt-asesores/",
      imgDesktop: gtAsesoresDesktop,
      imgMobile: gtAsesoresMobile,
      metrics: [
        {
          metric: "+32%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "x2",
          descripcion: "redujo el tiempo de recorrido de sus bases",
          color: "#5831D8",
        },
      ],
    },
    {
      image: IbrTes,
      title:
        "Con la validación de teléfonos de Beex hemos mejorado la contactabilidad de nuestra cartera",
      textbody:
        "IBR Perú, empresa líder en Contact Center & BPO, ha mejorado sus ratios de contacto gracias a la validación en tiempo real con Machine Learning de Beex.",
      name: "Michael Marcelo",
      post: "Sub-Gerente de Operaciones",
      link: "/casos-de-exito/ibr-peru/",
      imgDesktop: ibrDesktop,
      imgMobile: ibrMobile,
      metrics: [
     
      ],
    }, 
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Realiza llamadas automatizadas",
      description: "<p>Lanza miles de llamadas salientes al mismo tiempo a toda tu base de datos o a un grupo de deudores determinados. Asegura que todos tus agentes solo atiendan llamadas contestadas por una voz humana y categoriza los números apagados o inexistentes. Logra esto con nuestros marcadores basados en Machine Learning.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Envía notificaciones personalizadas",
      description: "<p>Notifica de manera automática a los deudores sobre fechas de pago, actualizaciones de políticas o deudas vencidas, tanto por llamadas, SMS, correo o por canales digitales como WhatsApp. Utiliza los datos del cliente para personalizar tus mensajes y así lograr una tasa de apertura ideal.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Utiliza la IA para tus operaciones",
      description: "<p>La integración de inteligencia artificial (IA) en nuestra plataforma te permite automatizar y optimizar diversas operaciones de cobranzas, como la asignación de prioridades a las cuentas deudoras, la personalización de mensajes de cobranza y la predicción de comportamientos de pago.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Integra tus sistemas internos",
      description: "<p>Centraliza tus operaciones de cobranzas al integrar sistemas internos como software de facturación, CRM o ERP. Maneja y actualiza toda la información en un solo lugar para optimizar la eficiencia y maximizar los resultados de tu gestión de cobros.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Recordatorios de pago por SMS e IVR",
      description: "<p>Crea workflows y automatiza el envío de recordatorios de pago por WhatsApp, SMS o email a los clientes que tengan cuotas pendientes y ofrecer opciones de pago a través de un flujo personalizado o la transferencia a un agente si es necesario.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "Software para Contact Center: 7 consideraciones para su elección",
    description: "<p>Es muy importante conocer las claves esenciales para seleccionar el software ideal para tu Contact Center, asegurando que se adapte perfectamente a tus necesidades específicas. <br /><br /> Nuestro Marketing Manager, Jorge Bautista, te brinda 7 consideraciones fundamentales que te ayudarán a tomar la mejor decisión</p>",
    image: imageVideo
  },
}

export default data
